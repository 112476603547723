import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Midsection from "../components/midsection";
import MainHero from "../components/mainHero";
import WhyUs from "../components/whyUs";
import OurWork from "../components/ourWork";
import ContactUs from "../components/contactUs";
import indexStyles from "./index.module.scss";

const IndexPage = ({ data }) => (
  <Layout>
    <MainHero />
    <Midsection />
    <WhyUs />

    <OurWork calmdog={data.calmdog} ecommerce={data.ecommerce} />
    <div className={`header has-background-primary ${indexStyles.blueDiv}`}>
      <svg
        style={{
          position: "absolute",
          bottom: "-1px",
          width: "100%",
          height: "5vw",
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="white" points="-5,100 100,0 100,100" />
      </svg>
    </div>
    <ContactUs />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  {
    calmdog: file(relativePath: { eq: "calmdog.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ecommerce: file(relativePath: { eq: "ecommerce.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
