import React from "react";
import { FaAws, FaReact, FaLongArrowAltRight } from "react-icons/fa";
import { GrGatsbyjs } from "react-icons/gr";
import { GiSpeedometer } from "react-icons/gi";
import { BsPhone } from "react-icons/bs";
import { DiJavascript1 } from "react-icons/di";

import { AiOutlineCloudServer } from "react-icons/ai";

import { Link } from "gatsby";
import midStyles from "./midsection.module.scss";

const Midsection = () => {
  const style2 = {
    display: "flex",
  };

  const renderService = (icon, title, id) => {
    return (
      <Link to={`services/${id}`} className={`${midStyles.service} level`}>
        <div style={style2}>
          <span className="icon is-medium" style={{ marginRight: "1.5rem" }}>
            {icon}
          </span>{" "}
          <p
            className="is-size-4 is-size-5-mobile subtitle"
            style={{ marginBottom: 0 }}
          >
            {title}
          </p>
        </div>
        <FaLongArrowAltRight
          id="arrow"
          className="has-text-danger"
          width="1.8"
          style={{ marginLeft: "1.5rem" }}
        />
      </Link>
    );
  };

  return (
    <section className={`section ${midStyles.sectionBody}`}>
      <div className="container">
        <h2
          className={`title is-size-2 has-text-primary ${midStyles.sectionTitle}`}
        >
          Our Services
        </h2>
        <div className="columns">
          <div className="column is-half">
            <div style={{ margin: "2rem 0rem", maxWidth: "450px" }}>
              <h3 className="title is-size-3">
                Modern solutions you will love
              </h3>
              <p className="subtitle is-size-5">
                We specialize in technology we know works and works well. You
                will love it, your users will love it, and your accounting team
                will think you forgot a zero.
              </p>
              <Link to="/services" className="button is-primary is-rounded">
                View All Services
              </Link>
            </div>
          </div>
          <div className="column">
            {renderService(
              <GiSpeedometer size="4rem" color="#325cc3" />,
              "Pre-rendered sites & apps",
              "#static"
            )}
            {renderService(
              <AiOutlineCloudServer size="4rem" color="#325cc3" />,
              "Serverless infrastructure",
              "#serverless"
            )}
            {renderService(
              <BsPhone size="4rem" color="#325cc3" />,
              "Progressive Web Apps (PWA)",
              "#pwa"
            )}
            {renderService(
              <DiJavascript1 size="4rem" color="#325cc3" />,
              "JAMStack",
              "#jam"
            )}
            {renderService(
              <GrGatsbyjs size="4rem" color="#325cc3" />,
              "Gatsby",
              "#gatsby"
            )}
            {renderService(
              <FaReact size="4rem" color="#325cc3" />,
              "React",
              "#react"
            )}
            {renderService(
              <FaAws size="4rem" color="#325cc3" />,
              "Amazon Web Services",
              "#aws"
            )}
            {/* {renderService(
                <FaTabletAlt className="has-text-danger" size="4rem" />,
                "Business Apps",
                "It’s a new way of building websites and apps that delivers better performance, higher security, lower cost of scaling, and a better developer experience."
              )}
              {renderService(
                <FaTabletAlt className="has-text-danger" size="4rem" />,
                "MVP Prototypes",
                "It’s a new way of building websites and apps that delivers better performance, higher security, lower cost of scaling, and a better developer experience."
              )}
              {renderService(
                <FaTabletAlt className="has-text-danger" size="4rem" />,
                "Integration with CRMS",
                "It’s a new way of building websites and apps that delivers better performance, higher security, lower cost of scaling, and a better developer experience."
              )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Midsection;
