import React from "react";
import Img from "gatsby-image";
import workStyles from "./ourWork.module.scss";

const OurWork = ({ calmdog, ecommerce }) => {
  const style1 = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: "2rem",
  };

  const renderExample = (title, description, image, url) => {
    return (
      <>
        <div className={`column is-half ${workStyles.image}`}>
          <div style={{ maxWidth: "420px", margin: "auto" }}>
            <Img
              fluid={image}
              className="image"
              style={{ borderRadius: "15px", minHeight: "250px" }}
            />
          </div>
        </div>
        <div className="column is-half">
          <div style={style1}>
            <p
              className="is-size-3 has-text-white"
              style={{ paddingBottom: "1rem" }}
            >
              {title}
            </p>
            <p
              className="subtitle  has-text-white"
              style={{
                paddingBottom: "2rem",
                maxWidth: "456px",
                lineHeight: 1.5,
              }}
            >
              {description}
            </p>

            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="button is-white is-rounded"
            >
              Check it out
            </a>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="has-background-primary section">
      <div className="container">
        <h2 className={`title is-size-2 has-text-white ${workStyles.title}`}>
          Case Study
        </h2>
        <div className="columns is-multiline">
          {renderExample(
            "Calm Dog PWA",
            "This Progressive Web App (PWA) helps dog owners with reactive dogs teach their dogs to calm down. It's installable, available on mobile and web and works offline. It was developed in a fraction of the time it takes to make traditional apps yet delivers just as well. We can update it in minutes, not days. It can harness traditional SEO for discovery and there are no fees from app stores.",
            calmdog.childImageSharp.fluid,
            "https://calmdog.app"
          )}

          {/* {renderExample(
            "Ecommerce thing",
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit Placeat quam ab odio? Et excepturi ad iure? Ullam culpa quae   aliquam.",
            ecommerce.childImageSharp.fluid,
            "url"
          )} */}
        </div>
      </div>
    </section>
  );
};

export default OurWork;
