import React from "react";
import heroStyles from "./mainHero.module.scss";
import WorkTogetherSVG from "./svg/work-together";

const MainHero = () => (
  <section className="hero is-fullheight-with-navbar">
    <div className={`has-background-primary ${heroStyles.blueSpacer}`}></div>
    <div className={`header has-background-primary ${heroStyles.blueDiv}`}>
      <svg
        className={heroStyles.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="white" points="-5,100 100,0 100,100" />
      </svg>
    </div>
    <div className="hero-body">
      <div className="container">
        <div className="columns level">
          <div
            className="column is-full-mobile is-one-third"
            style={{ marginRight: "5rem" }}
          >
            <div className="content">
              <h1 className="is-size-1 has-text-primary">Modern Software</h1>
              <p
                className="subtitle is-size-4 is-size-5-mobile"
                style={{ maxWidth: "450px" }}
              >
                Peak Inertia is a custom software agency specializing in mobile
                & web applications
              </p>
              <div className="box level" style={{ marginTop: "3rem" }}>
                <div>
                  <p>
                    Phone: <a href="tel:1-248-266-0452">(248)266-0452</a>
                  </p>
                  <p>
                    Email:{" "}
                    <a href="mailto:info@peakinertia.com?subject=Interested in working with Peak Inertia">
                      info@peakinertia.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <WorkTogetherSVG width="100%" />
            {/* <img
              src={DesignNotes}
              alt="A sketch of a man standing beside a web design"
              style={{
                width: "200%",
                paddingTop: "2rem",
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MainHero;
