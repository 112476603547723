import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "gatsby";
import usStyles from "./whyUs.module.scss";

const WhyUs = () => {
  const renderSkill = (title, subTitle) => {
    return (
      <article className="media">
        {/* <figure className="media-left is-hidden-mobile	">
          <span className="icon is-medium">
            <FaRegCheckCircle
              className="has-text-white"
              size="4rem"
              style={{ paddingTop: "1.8rem" }}
            />
          </span>
        </figure> */}
        <div className="media-content" style={{ overflowX: "visible" }}>
          <div className="content">
            <h3
              className="title is-size-3 has-text-white"
              style={{ paddingTop: "0.8rem" }}
            >
              {title}
            </h3>
            <p className="subtitle is-size-5 has-text-white">{subTitle}</p>
          </div>
        </div>
      </article>
    );
  };

  return (
    <section
      className="has-background-primary section"
      style={{ position: "relative" }}
    >
      <div
        className="has-background-primary"
        style={{
          height: "100px",
          width: "100%",
          top: "-100px",
          left: 0,
          position: "absolute",
        }}
      >
        <svg
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "5vw",
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="0,0 100,0 0,100" />
        </svg>
      </div>
      <div className={`container ${usStyles.containerBody}`}>
        <h2
          className="title is-size-2 has-text-white"
          style={{ paddingBottom: "2rem" }}
        >
          Software Consulting that Works
        </h2>
        <div className="columns">
          <div className="column is-half">
            <div style={{ marginTop: "2rem", maxWidth: "450px" }}>
              <h3 className="title is-size-3 has-text-white">
                We designed our agency{<br />} for you
              </h3>
              <p className="subtitle is-size-5 has-text-white">
                From our technology to our client relationships, we have
                designed everything at Peak Inertia to make working with us an
                enjoyable experience.
              </p>
              <Link to="/services" className="button is-white is-rounded">
                Why Peak Inertia?
              </Link>
            </div>
          </div>
          <div className="column" style={{ paddingTop: "2rem" }}>
            {renderSkill(
              "Small, nimble & fast",
              "When you call, a software developer answers. As a small agency we offer a service that feel like you're working wiht a developer at your office — someone who you know, trust, and have a relationship with."
            )}
            {renderSkill(
              "Our business model is client retention",
              "Our goal is to keep you as a client for as long as you need software. So, we not only make sure you are happy with everything that we build, but that you enjoy working with us every step of the way"
            )}
            {renderSkill(
              "We picked our technology for you",
              "We’ve considered cost, ease-of-use, maintenance, speed, reliability and so much more to select our technology that delivers for our clients time and again"
            )}
            {renderSkill(
              "We build what you need",
              "We focus on creating software that solves your problems. First, we listen to fully understand your problem, and then we create the perfect software solution"
            )}
            {renderSkill(
              "Whether you are starting a new project or fixing an old one",
              "Our engineers have extensive experience working with a diverse array of technology. We can adapt to the legacy applications you have and work them into modern software solutions. Or we would love to build you something fresh"
            )}
            {renderSkill(
              "Full-stack solutions",
              "Front-end, back-end, databases, APIs, design, user-experience, are all in our wheel-house"
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
